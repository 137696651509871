import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import Layout from "@components/Layout/Layout"
import StaticData from "@components/StaticData/StaticData"
import Seo from "@components/seo"
import loadable from "@loadable/component"
import "@styles/main.scss"
import { graphql } from "gatsby"
import React, { useEffect } from "react"
const VideoAskWidget = loadable(() => import("@components/VideoAsk/VideoAsk"))
const StaticTemplate = ({ data }) => {
  const pageData = data?.strapiPage
  const menuData = pageData?.menu
  const banner = pageData?.banner
  useEffect(() => {
    if (sessionStorage.getItem("searchpath") && typeof window !== "undefined") {
      sessionStorage.removeItem("searchpath");
    }
  }, []);
  return (
    <Layout pageData={pageData}>
      <Breadcrumbs menuData={menuData} />
      {pageData?.video_ask_url &&
<VideoAskWidget video_ask_url={pageData?.video_ask_url} />
}
      <StaticData
        title={banner?.title}
        description={banner?.description?.data?.description}
      />
    </Layout>
  )
}

export default StaticTemplate

export const Head = ({ data }) => {
  const pageData = data?.strapiPage

  return (
    <Seo
      title={pageData?.seo?.metaTitle}
      description={pageData?.seo?.metaDescription}
      customCanonical={pageData?.seo?.canonicalURL}
    />
  )
}

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...pageFragment
    }
  }
`
